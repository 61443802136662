import { Pipe, PipeTransform } from '@angular/core';
import { TranslationsService } from './translations.service';
@Pipe({
  name: 'translate',
  pure: false
})
export class TranslationsPipe implements PipeTransform {

  constructor() {
    //console.log("TranslationsPipe constructor",TranslationsService.transtationData);
  }
  transform(key: any): any {
    const keys: string[] = key.split('.'); 
  // console.log("data",TranslationsService.translate);
   //console.log("data[keys[0]]",this.translate.data[keys[0]]);
  // console.log("data[keys[0]][keys[1]]",this.translate.data[keys[0]][keys[1]]);
    return (TranslationsService.transtationData[keys[0]][keys[1]] != undefined 
      || TranslationsService.transtationData[keys[0]][keys[1]] != null) ? TranslationsService.transtationData[keys[0]][keys[1]] : key;
    //return this.translate.data[key] || key;
  }

}
