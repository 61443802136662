import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'searchFilter'
})
export class SearchFilterPipe implements PipeTransform {
    transform(data: any[], search: string, fieldsToFilter: string[], statusId: number): any {
      var filteredData =[];
      var tmpFilteredData =[];
//console.log("data",data);
      if (data) {
        if (search === undefined || search.length < 1) {
          if (statusId == -1) {
            return data;
          } else {
            filteredData = data.filter(obj => obj['OrderStatusId'] == statusId);
            return filteredData;
          }
        } else {
          if (statusId == -1) {
            tmpFilteredData= data;
           
          } else {
            tmpFilteredData = data.filter(obj => obj['OrderStatusId'] == statusId);
           
          }
          for (let i = 0; i <fieldsToFilter.length; i++) { 
            filteredData = filteredData.concat(tmpFilteredData.filter((obj) =>
              obj[fieldsToFilter[i]]?.toString().toLowerCase().includes(search.toLowerCase())
              ));             
          }     
          console.log("filteredData",filteredData)   ;
            return filteredData;
        }    
      } else {
        return [];
      }
    }
}