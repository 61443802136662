import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Injectable()
export class TranslationsService {

  static transtationData: any = {};
   private defaultLanguage = environment.language || 'he';
   //private defaultLanguage = 'en';

  private currentLanguage = this.defaultLanguage;

  constructor(private http: HttpClient) {}

  public use(lang?: string): Promise<{}> {
    console.log("use");
    return new Promise<{}>((resolve, reject) => {
      this.currentLanguage = lang || this.defaultLanguage;
      const langPath = `assets/i18n/${this.currentLanguage.toLowerCase()}.json`;
      console.log("langPath",langPath);
      this.http.get<{}>(langPath).subscribe(
        translation => {
          console.log("translation",translation);
          TranslationsService.transtationData = Object.assign({}, translation || {});
          console.log("this.data",TranslationsService.transtationData);
          resolve(TranslationsService.transtationData);
        },
        (error) => {
          console.log("error this.data",TranslationsService.transtationData);
          TranslationsService.transtationData = {};
          resolve(TranslationsService.transtationData);
        }
      );
    });
    
  }

  public language() {
    return this.currentLanguage.toLowerCase();
  }

  translate(key: any): any {   
    const keys: string[] = key.split('.');
    // return (this.data[key] != undefined || this.data[key] != null) ? this.data[key] : key;
    return (TranslationsService.transtationData[keys[0]][keys[1]] != undefined 
      || TranslationsService.transtationData[keys[0]][keys[1]] != null) ? TranslationsService.transtationData[keys[0]][keys[1]] : key;
  }

  
}
