import {Component} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { navItems } from '../../_nav';
import { SignInOutService } from '../../core/services/sign-in-out.service';
import {AppStorageService} from "../../app.storage.service";
import { StorageValueEnum } from '../../enums/advanced/storage-value.enum';
import { TranslationsService } from '../../core/translations/translations.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html'
})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navItems;
  public franchise: any;
  public branch: any;

  constructor(private router: Router,
    private signInOutService: SignInOutService,
    private translationService: TranslationsService,
    public appStorageService: AppStorageService) {

      this.franchise = appStorageService.franchise;
      //this.branch = appStorageService.getItemFromLocalStorage(StorageValueEnum.BRANCH_NAME);
  }


  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  public logout() {
    this.signInOutService.logout()
    .subscribe((response) => {     
      if (response) {
        //this.appStorageService.orders=[];
        this.router.navigate(['/login']);
      } 
    }, (error) => {
      //this.messageService.displayServerErrorMessage();
      console.log("error",error);
    });
  }

  public displaySideBar():boolean {
    console.log("displaySideBar", this.appStorageService.displaySideBar)
    return this.appStorageService.displaySideBar;
  }

}
