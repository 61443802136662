import { Component, OnInit } from '@angular/core';
//import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MetaDataService } from './core/services/meta-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT, PlatformLocation } from '@angular/common';
import { AppStorageService } from './app.storage.service';
import { SignInOutService } from './core/services/sign-in-out.service';
import { StorageValueEnum } from './enums/advanced/storage-value.enum';
import { BrowserIdentificatorService } from "./core/services/common-settings/browser-identificator.service";
import { PreviousRouteService } from "./core/services/common-settings/previous-route.service";
import { CommonModule } from '@angular/common';
import { TranslationsService } from './core/translations/translations.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  _deviceInfo = null;
  constructor(private router: Router,
    private translate: TranslationsService,
    private translationsService: TranslationsService,
              private signInOutService: SignInOutService,
              private appStorageService: AppStorageService,
              private deviceService: DeviceDetectorService) { 
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {         
        return;
      }
     // const url = evt.urlAfterRedirects;
      //  console.log('url is', url);
      window.scrollTo(0, 0);
    });

  }


  ngOnInit() {
    console.log("ngOnInit");
    try {
      this._deviceInfo = this.deviceService.getDeviceInfo();
      console.log( this._deviceInfo);
      this.signInOutService.getCurrentUser().subscribe((response) => {    
        if (response) {
          console.log("getCurrentUser response", response);
          if  (!response.Id) {
            console.log(" this.router.navigate(['/login']);");
            this.router.navigate(['/login']);
          } else {
            //this.router.navigate(['/franchise']);
          }
        } else {
          console.log(" this.router.navigate(['/login']);");
          this.router.navigate(['/login']);
        }
        
      });
    
    } catch (e) {
      console.log (e);
      console.log(" this.router.navigate(['/login']);", e);
      this.router.navigate(['/login']);
    }
   

   /* this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    }); */
  }
}
