import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ElectronService } from 'ngx-electron';


@Injectable()
export class IncomingOrderService {

  constructor(
    private http: HttpClient
    //,private electron: ElectronService
    ) { }

    /*
  public printOrder(order) {
    let printCreditUrl = `${environment.serverUrl}IncomingOrders/PrintCredit?orderId=${order.Id}?lang=${environment.language}`;
    let printOrder = `${environment.serverUrl}IncomingOrders/PrintOrder?orderId=${order.Id}?lang=${environment.language}`;
    
    //normal 
    if (!this.electron.isElectronApp) {
      if (order.PaymentTypeId == 6 && order.CreditReciptData != undefined && order.CreditReciptData != '') {
        window.open(printCreditUrl, '_blank');
      }
      window.open(printOrder, '_blank');
    }

    //electron
    else {
      let urls: Array<string>;
      if (order.PaymentTypeId == 6 && order.CreditReciptData != undefined && order.CreditReciptData != '') {
        urls = new Array(printOrder, printCreditUrl);
      } else {
        urls = new Array(printOrder);
      }
      this.electron.ipcRenderer.send('print', urls);
    }
  }
  */

  public getOrder(orderId): Observable<any> {
    return this.http.get(environment.serverUrl +
      'IncomingOrders/GetOrder?orderId=' + orderId + "&lang=" + environment.language);
  }

  public getIncomingOrders(branchId, franchiseId, statusId, fromDate): Observable<any> {
    // if(statusId == -2) {
    //  return this.http.get(environment.serverUrl +
    //     'IncomingOrders/GetIncomingOrders?fromDate=' + fromDate);
    //} else {
    return this.http.get(environment.serverUrl +
      'IncomingOrders/GetIncomingOrders?branchId=' + branchId 
      + '&franchiseId=' + franchiseId
      + '&statusId=' + statusId       
      + '&fromDate=' + fromDate);
    // }

  }

  public getNewIncomingOrders(lastOrderId): Observable<any> {
    return this.http.get(environment.serverUrl +
      'IncomingOrders/GetIncomingOrders?lastOrderId=' + lastOrderId);
  }

  public getOrderStatuses(): Observable<any> {
    return this.http.get(environment.serverUrl +
      'IncomingOrders/GetOrderStatuses');
  }

  public setOrderStatus(orderId, statusId, deliveryGuyId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'IncomingOrders/SetOrderStatus',
      {
        orderId: orderId,
        statusId: statusId,
        deliveryGuyId: deliveryGuyId
      });
  }

}
