import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ConfigService {

  constructor(private http: HttpClient) {}

  public serverUrl: string;
  public franchiseId: number;
  public pwaUrl: string;
  public imagePath: string;
  public configSettings: any={};

  //public currentVersion = environment.currentVersion;
  //public country = environment.country;

}
