import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class BranchService {

  constructor(private http: HttpClient) {
  
  }

  /// Branches ///

  public getAllBranches(franchiseId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Branch/GetAll?franchiseId='+ franchiseId);
  }

  public deleteBranch(branchId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/DeleteBranch', 
      {
        id: branchId
      });
  }

  public undeleteBranch(branchId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/UnDeleteBranch', 
      {
        id: branchId
      });
  }

  public CopyFranchiseMenuToBranch(franchiseId, branchId): Observable<any> {   
    return this.http.post<any>(environment.serverUrl +
      'Menu/CopyFranchiseMenuToBranch', 
      {
        franchiseId: franchiseId,
        branchId: branchId
      });
  }

  public CopyBranchMenuToBranch(fromBranchId, toBranchId): Observable<any> {   
    return this.http.post<any>(environment.serverUrl +
      'Menu/CopyBranchMenuToBranch', 
      {
        fromBranchId: fromBranchId,
        toBranchId: toBranchId
      });
  }

  // whatsapp users
  public getBranchWhatsappNotificationUsers(branchId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Branch/GetBranchWhatsappNotificationUsers?&branchId='+ branchId);
  }

  public createBranchWhatsappNotificationUser(branchId, name, phone): Observable<any> {   
    //CreateBranchWhatsappNotificationUser(int branchId, string name, string phone)
    return this.http.post<any>(environment.serverUrl +
      'Branch/CreateBranchWhatsappNotificationUser', 
      {
        branchId: branchId,
        name: name,
        phone: phone
      });
  }
  
  public updateBranchWhatsappNotificationUser(userId, name, phone): Observable<any> {   
    //CreateBranchWhatsappNotificationUser(int branchId, string name, string phone)
    return this.http.post<any>(environment.serverUrl +
      'Branch/UpdateBranchWhatsappNotificationUser', 
      {
        userId: userId,
        name: name,
        phone: phone
      });
  }

  public deleteBranchWhatsappNotificationUser(userId): Observable<any> {   
    //CreateBranchWhatsappNotificationUser(int branchId, string name, string phone)
    return this.http.post<any>(environment.serverUrl +
      'Branch/DeleteBranchWhatsappNotificationUser', 
      {
        userId: userId
      });
  }
  /// Branch Form ///

  public getCities()  {   
    return new Promise((resolve, reject) => {
      this.http.get(environment.serverUrl + 'Branch/GetCities')
        .subscribe((response) => {
          console.log('getCities', response);
          var data = JSON.parse(response.toString());
          var cities = data.result.records;
         resolve(cities);
        }, error => {
          console.log('BranchService: getCities - Error', error);
        });
    });
    
    
  }

  public getBranch(branchId, franchiseId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'Branch/GetBranch?branchId=' + branchId + "&franchiseId="+ franchiseId);
  }


  public generateGuid(branchId): Observable<any> {   
    return this.http.get(environment.serverUrl +
      'CallerID/GenerateBranchGuid?branchId=' + branchId );
  }

  public createBranch(branch): Observable<any> {
    var fd = new FormData();
      fd.append("FranchiseId", branch.FranchiseId);
      if (branch.AttachedFranchiseId) fd.append("AttachedFranchiseId", branch.AttachedFranchiseId);
      if (branch.AttachedBranchId) fd.append("AttachedBranchId", branch.AttachedBranchId);
      fd.append("Name", branch.Name);
      fd.append("BranchPhone", branch.BranchPhone);
      fd.append("BranchPhone2", branch.BranchPhone2);
      fd.append("ManagerName", branch.ManagerName);
      fd.append("ManagerPhone", branch.ManagerPhone);
      fd.append("ManagerMobile", branch.ManagerMobile);
      fd.append("TimeUntilUpdateOfUndealedOrder", branch.TimeUntilUpdateOfUndealedOrder);
      fd.append("TimeUntilTwilioAlert_First", branch.TimeUntilTwilioAlert_First);
      fd.append("TwilioAlertPhoneNumber_First", branch.TwilioAlertPhoneNumber_First);
      fd.append("TimeUntilTwilioAlert_Second", branch.TimeUntilTwilioAlert_Second);
      fd.append("TwilioAlertPhoneNumber_Second", branch.TwilioAlertPhoneNumber_Second);
      fd.append("Guid", branch.Guid);
      fd.append("ManagerEmail", branch.ManagerEmail);
      fd.append("ManagerPhone", branch.ManagerPhone);
      fd.append("Address", branch.Address);
      fd.append("Longitude", branch.Longitude);
      fd.append("Latitude", branch.Latitude);
      fd.append("ImageFile", branch.ImageFile);
      fd.append("DeliveryTimeInMinutes", branch.DeliveryTimeInMinutes);
      fd.append("TakeawayTimeInMinutes", branch.TakeawayTimeInMinutes);
      fd.append("TranzilaTerminal", branch.TranzilaTerminal);
      fd.append("TranzilaPassword", branch.TranzilaPassword);
      fd.append("TranzilaPrivateKey", branch.TranzilaPrivateKey);
      fd.append("TranzilaPublicKey", branch.TranzilaPublicKey);
      fd.append("TranzilaPosId", branch.TranzilaPosId);
     // fd.append("TranzilaSupplier", branch.TranzilaSupplier);
      fd.append("TranzilaInvoiceModule", branch.TranzilaInvoiceModule);
      fd.append("SendInvoiceForKioskOrders", branch.SendInvoiceForKioskOrders);
      fd.append("SendInvoiceForKioskCashOrders", branch.SendInvoiceForKioskCashOrders);
      fd.append("SendInvoiceForCreditOrders", branch.SendInvoiceForCreditOrders);
      fd.append("SendInvoiceForCashOrders", branch.SendInvoiceForCashOrders);
      fd.append("SendInvoiceForInSitePinPadOrders", branch.SendInvoiceForInSitePinPadOrders);
      fd.append("SendInvoiceForInSiteTranzilaOrders", branch.SendInvoiceForInSiteTranzilaOrders);
      fd.append("SendInvoiceForInSiteCashOrders", branch.SendInvoiceForInSiteCashOrders);
      fd.append("WisepayIpAdress", branch.WisepayIpAdress);
      fd.append("WisepayPassword", branch.WisepayPassword);
      fd.append("IsDelivery", branch.IsDelivery);
      fd.append("PelecardTerminalNumber", branch.PelecardTerminalNumber);
      fd.append("PelecardUser", branch.PelecardUser);
      fd.append("PelecardPassword", branch.PelecardPassword);
      
      fd.append("NoVat", branch.NoVat);
      fd.append("Cash", branch.Cash);
      fd.append("CreditCard", branch.CreditCard);
      fd.append("CreditPhone", branch.CreditPhone);
      fd.append("IsCustomPayment", branch.IsCustomPayment);
      fd.append("CustomPayment", branch.CustomPayment);

      fd.append("IsSit", branch.IsSit);
      fd.append("IsTakeAway", branch.IsTakeAway);
      fd.append("PrintNumber", branch.PrintNumber);
      fd.append("CashRegisterId", branch.CashRegisterId);
      fd.append("CashRegisterParams", branch.CashRegisterParams);
      fd.append("SendSmsWhenReady", branch.SendSmsWhenReady);
      fd.append("SmsSenderName", branch.SmsSenderName);
      fd.append("Country", environment.country);
      fd.append("LionwheelIntegration", branch.LionwheelIntegration);
      fd.append("LionwheelToken", branch.LionwheelToken);
      fd.append("MeshulamUserId", branch.MeshulamUserId);
      fd.append("IsUseMailOrder", branch.IsUseMailOrder);
      fd.append("SendInvoiceToCustomer", branch.SendInvoiceToCustomer);
      fd.append("SendInvoiceToKioskCustomer", branch.SendInvoiceToKioskCustomer);
      fd.append("UseTranzilaIframe", branch.UseTranzilaIframe);
      fd.append("TranzilaIframeUrl", branch.TranzilaIframeUrl);
      if (branch.EmailsToNotify) fd.append("EmailsToNotify", branch.EmailsToNotify);
      if (branch.CallerIdSmsText) fd.append("CallerIdSmsText", branch.CallerIdSmsText);
      if (branch.UsaTaxProc) fd.append("UsaTaxProc", branch.UsaTaxProc);

      console.log(branch.DayWorkingHours);
      branch.DayWorkingHours.forEach(function (item, index) {
     //   fd.append("DayWorkingHours[" + index + "].BranchId", item.BranchId);
     //   fd.append("DayWorkingHours[" + index + "].Id", '0');
        fd.append("DayWorkingHours[" + index + "].PickupMethod", item.PickupMethod);
     //   fd.append("DayWorkingHours[" + index + "].DayOfWeekId", item.DayOfWeekId);
      //  fd.append("DayWorkingHours[" + index + "].StartTime", item.StartTime);
      //  fd.append("DayWorkingHours[" + index + "].FinishTime", item.FinishTime);
        fd.append("DayWorkingHours[" + index + "].DayOfWeekId", item.DayOfWeekId);
        fd.append("DayWorkingHours[" + index + "].StartTime", item.StartTime);
        fd.append("DayWorkingHours[" + index + "].FinishTime", item.FinishTime);
      });
      console.log(fd);
    return this.http.post<any>(environment.serverUrl +
      'Branch/CreateBranch', fd );
  }

  public updateBranch(branch): Observable<any> {
    console.log(branch);
    var fd = new FormData();
    fd.append("FranchiseId", branch.FranchiseId);
    if (branch.AttachedFranchiseId) fd.append("AttachedFranchiseId", branch.AttachedFranchiseId);
    if (branch.AttachedBranchId) fd.append("AttachedBranchId", branch.AttachedBranchId);
    fd.append("Id", branch.Id);
    fd.append("Name", branch.Name);
    fd.append("BranchPhone", branch.BranchPhone);
    fd.append("BranchPhone2", branch.BranchPhone2);
    fd.append("ManagerName", branch.ManagerName);
    fd.append("ManagerPhone", branch.ManagerPhone);
    fd.append("ManagerMobile", branch.ManagerMobile);
    fd.append("TimeUntilUpdateOfUndealedOrder", branch.TimeUntilUpdateOfUndealedOrder);
    fd.append("TimeUntilTwilioAlert_First", branch.TimeUntilTwilioAlert_First);
    fd.append("TwilioAlertPhoneNumber_First", branch.TwilioAlertPhoneNumber_First);
    fd.append("TimeUntilTwilioAlert_Second", branch.TimeUntilTwilioAlert_Second);
    fd.append("TwilioAlertPhoneNumber_Second", branch.TwilioAlertPhoneNumber_Second);
    fd.append("ManagerEmail", branch.ManagerEmail);
    fd.append("ManagerPhone", branch.ManagerPhone);
    fd.append("Address", branch.Address);
    fd.append("Longitude", branch.Longitude);
    fd.append("Latitude", branch.Latitude);
    fd.append("ImageFile", branch.ImageFile);
    fd.append("DeliveryTimeInMinutes", branch.DeliveryTimeInMinutes);
    fd.append("TakeawayTimeInMinutes", branch.TakeawayTimeInMinutes);
    fd.append("TranzilaTerminal", branch.TranzilaTerminal);
    fd.append("TranzilaPassword", branch.TranzilaPassword);
    fd.append("TranzilaPrivateKey", branch.TranzilaPrivateKey);
    fd.append("TranzilaPublicKey", branch.TranzilaPublicKey);
    fd.append("TranzilaPosId", branch.TranzilaPosId);
    //fd.append("TranzilaSupplier", branch.TranzilaSupplier);
    fd.append("TranzilaInvoiceModule", branch.TranzilaInvoiceModule);
    fd.append("SendInvoiceForKioskOrders", branch.SendInvoiceForKioskOrders);
    fd.append("SendInvoiceForKioskCashOrders", branch.SendInvoiceForKioskCashOrders);
    fd.append("SendInvoiceForCreditOrders", branch.SendInvoiceForCreditOrders);
    fd.append("SendInvoiceForCashOrders", branch.SendInvoiceForCashOrders);
    fd.append("SendInvoiceForInSitePinPadOrders", branch.SendInvoiceForInSitePinPadOrders);
    fd.append("SendInvoiceForInSiteTranzilaOrders", branch.SendInvoiceForInSiteTranzilaOrders);
    fd.append("SendInvoiceForInSiteCashOrders", branch.SendInvoiceForInSiteCashOrders);
    fd.append("WisepayIpAdress", branch.WisepayIpAdress);
    fd.append("WisepayPassword", branch.WisepayPassword);
    fd.append("PelecardTerminalNumber", branch.PelecardTerminalNumber);
    fd.append("PelecardUser", branch.PelecardUser);
    fd.append("PelecardPassword", branch.PelecardPassword);
    fd.append("NoVat", branch.NoVat);
    fd.append("Cash", branch.Cash);
    fd.append("CreditCard", branch.CreditCard);
    fd.append("CreditPhone", branch.CreditPhone);
    fd.append("IsCustomPayment", branch.IsCustomPayment);
    fd.append("CustomPayment", branch.CustomPayment);

    fd.append("IsDelivery", branch.IsDelivery);
    fd.append("IsSit", branch.IsSit);
    fd.append("IsTakeAway", branch.IsTakeAway);
    fd.append("IsDigitalMenu", branch.IsDigitalMenu);
    fd.append("PrintNumber", branch.PrintNumber);
    fd.append("Guid", branch.Guid);
    fd.append("CashRegisterId", branch.CashRegisterId);
    fd.append("SendSmsWhenReady", branch.SendSmsWhenReady);
    fd.append("SmsSenderName", branch.SmsSenderName);
    fd.append("CashRegisterParams", branch.CashRegisterParams);
    fd.append("Country", environment.country);
    fd.append("IsUseMailOrder", branch.IsUseMailOrder);
    fd.append("SendWhatsapp", branch.SendWhatsapp);
    fd.append("WhatsappMessage", branch.WhatsappMessage);
    fd.append("OneClickInstanceId", branch.OneClickInstanceId);
    fd.append("OneClickToken", branch.OneClickToken);
    fd.append("MeshulamUserId", branch.FutureDeliveryOptions);
    fd.append("NameOnInvoice", branch.NameOnInvoice);
    fd.append("AddressOnInvoice", branch.AddressOnInvoice);
    fd.append("CompanyId", branch.CompanyId);
    fd.append("LionwheelIntegration", branch.LionwheelIntegration);
    fd.append("LionwheelToken", branch.LionwheelToken);
    fd.append("MeshulamUserId", branch.MeshulamUserId);
    fd.append("SendInvoiceToCustomer", branch.SendInvoiceToCustomer);
    fd.append("SendInvoiceToKioskCustomer", branch.SendInvoiceToKioskCustomer);
    fd.append("MinSumForVouchers", branch.MinSumForVouchers);
    fd.append("UseTranzilaIframe", branch.UseTranzilaIframe);
    fd.append("TranzilaIframeUrl", branch.TranzilaIframeUrl);
    if (branch.EmailsToNotify) fd.append("EmailsToNotify", branch.EmailsToNotify);
    if (branch.CallerIdSmsText) fd.append("CallerIdSmsText", branch.CallerIdSmsText);
    if (branch.UsaTaxProc) fd.append("UsaTaxProc", branch.UsaTaxProc);

    branch.DayWorkingHours.forEach(function (item, index) {
      fd.append("DayWorkingHours[" + index + "].BranchId", item.BranchId);
      fd.append("DayWorkingHours[" + index + "].Id", item.Id);
      fd.append("DayWorkingHours[" + index + "].PickupMethod", item.PickupMethod);
      fd.append("DayWorkingHours[" + index + "].DayOfWeekId", item.DayOfWeekId);
      fd.append("DayWorkingHours[" + index + "].StartTime", item.StartTime);
      fd.append("DayWorkingHours[" + index + "].FinishTime", item.FinishTime);
    });

    branch.Messages.forEach( (item, index)=> {
      fd.append("Messages[" + index + "].BranchId", item.BranchId);
      fd.append("Messages[" + index + "].Id", item.Id);
      fd.append("Messages[" + index + "].Message", item.Message);
      fd.append("Messages[" + index + "].Order", item.Order);
      fd.append("Messages[" + index + "].IsActive", item.IsActive); 
      fd.append("Messages[" + index + "].DisplayInEndOfOrder", item.DisplayInEndOfOrder);
      console.log("item.ImageFile",item.ImageFile) 
      if (item.ImageFile != null && item.ImageFile!= undefined && item.ImageFile!="") {
        var byteString = atob(item.ImageFile.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        item.ImageFile = new Blob([ab], { type: 'image/jpeg' });
        fd.append("Messages[" + index + "].ImageFile", item.ImageFile);
      }       
      else  fd.append("Messages[" + index + "].ImageFile", null);
    });

    return this.http.post<any>(environment.serverUrl +
      'Branch/UpdateBranch', fd );
  }

  public updateBranch_(branch): Observable<any> {
    console.log(branch);
    
    return this.http.post<any>(environment.serverUrl +
      'Branch/UpdateBranch',  {
        webModel: branch
      });
  }

  public addDeliveryGroup(branchId, deliveryGroup): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/AddDeliveryGroup', 
      {
        webModel: deliveryGroup,
        branchId: branchId
      });
  }

  public updateDeliveryGroup(deliveryGroup): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/UpdateDeliveryGroup', 
      {
        webModel: deliveryGroup 
      });
  }

  public removeDeliveryGroup(deliveryGroupId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/RemoveDeliveryGroup', 
      {
        deliveryGroupId: deliveryGroupId
      });
  }
  
  public updateCloseDate(closeDate): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/UpdateCloseDate', 
      {
        webModel: closeDate
      });
  }

  public addCloseDate(branchId, closeDate): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/AddCloseDate', 
      {
        webModel: closeDate,
        branchId: branchId
      });
  }

  public removeCloseDate(closeDateId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/RemoveCloseDate', 
      {
        closeDateId: closeDateId
      });
  }

  public saveWorkingHoursBranch(formData: FormData): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Branch/SaveWorkingHoursBranch', formData );
  }

 
  public b64toBlob(dataURI):Blob {
    console.log("dataURI",dataURI);
    var byteString = atob(dataURI.split(',')[1]);
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
  }
  
 


}



