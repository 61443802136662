import { Component } from '@angular/core';
import { StorageValueEnum } from '../../enums/advanced/storage-value.enum';
import {SignInOutService} from "../../core/services/sign-in-out.service";
import {AppStorageService} from "../../app.storage.service";
import {Router} from '@angular/router';
//import {AlertsComponent} from "../notifications/alerts.component";

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent { 

  public userName :string = '';
  public userPassword :string = '';
  public invalidUsernameOrPassword: boolean=false;

  constructor( private router: Router,
    //private alertsComponent: AlertsComponent,
    //protected translationService: TranslationsService,
    //protected configService: ConfigService,
    protected signInOutService: SignInOutService,
    protected appStorageService: AppStorageService,
   
    //public commonFunctionsService: CommonFunctionsService,
    //protected browserService: BrowserIdentificatorService,
    ) {

      }


  ngOnInit() {}

  public login() {
console.log("login");
    this.signInOutService.signIn(this.userName, this.userPassword)
    .subscribe((response) => { 
      console.log("login response",response);

      if (response.Success) {
        console.log("this.router.navigate(['franchise']);");

        this.router.navigate(['franchise']);

       
      } else {
        this.appStorageService.userName = '';
            //$scope.username = '';
            //$scope.password = '';
            this.invalidUsernameOrPassword = true;
      }
    }, (error) => {
      //this.messageService.displayServerErrorMessage();
      console.log("error",error);
      this.appStorageService.userName = '';
      //$scope.username = '';
      //$scope.password = '';
      this.invalidUsernameOrPassword = true;
    });

  }

  public forgotPassword() {}
}
