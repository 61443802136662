import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Import Containers
import { DefaultLayoutComponent } from './containers';
//import {FranchiseFormComponent} from './views/franchise-form/franchise-form.component';
import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: '/franchise',
    pathMatch: 'full',
  },
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404'
    }
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500'
    }
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    }
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register Page'
    }
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'ראשי - כל הרשתות'
    },
    children: [
      {
        path: 'i18',        
        loadChildren: () => import('./views/localization/localization.module').then(m => m.LocalizationModule)
      },
      {
        path: 'roles',        
        loadChildren: () => import('./views/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'settings',        
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule)
      },
      {
        path: 'franchise',        
        loadChildren: () => import('./views/franchise/franchise.module').then(m => m.FranchiseModule)
      },
      {
        path: 'franchise-form/:franchiseId',        
        loadChildren: () => import('./views/franchise-form/franchise-form.module').then(m => m.FranchiseFormModule)
      },
      {
        path: 'branches/:franchiseId',        
        loadChildren: () => import('./views/branches/branches.module').then(m => m.BranchesModule)
      },
      {
        path: 'i18/:franchiseId',        
        loadChildren: () => import('./views/franchise-i18/franchise-i18.module').then(m => m.FranchiseI18Module)
      },
      {
        path: 'categories/:franchiseId',        
        loadChildren: () => import('./views/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'categories/:franchiseId/:branchId',        
        loadChildren: () => import('./views/categories/categories.module').then(m => m.CategoriesModule)
      },
      {
        path: 'members-club/:franchiseId',        
        loadChildren: () => import('./views/members-club/members-club.module').then(m => m.MembersClubModule)
      },
      {
        path: 'garnish-groups/:franchiseId/:branchId',        
        loadChildren: () => import('./views/general-garnish-group/general-garnish-group.module').then(m => m.GeneralGarnishGroupModule)
      },
      {
        path: 'combos/:franchiseId/:branchId',        
        loadChildren: () => import('./views/combos/combos.module').then(m => m.CombosModule)
      },
      {
        path: 'kiosks/:franchiseId',        
        loadChildren: () => import('./views/kiosks/kiosks.module').then(m => m.KiosksModule)
      },
      {
        path: 'users/:franchiseId',        
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'scratch-cupons/:franchiseId',        
        loadChildren: () => import('./views/scratch-cupons/scratch-cupons.module').then(m => m.ScratchCuponsModule)
      },
      {
        path: 'all-cupons/:franchiseId',        
        loadChildren: () => import('./views/all-cupons/all-cupons.module').then(m => m.AllCuponsModule)
      },
      {
        path: 'combo-form/:franchiseId/:branchId/:comboId',        
        loadChildren: () => import('./views/combo-form/combo-form.module').then(m => m.ComboFormModule)
      },
      {
        path: 'pizzas/:franchiseId/:branchId',        
        loadChildren: () => import('./views/pizza/pizza.module').then(m => m.PizzaModule)
      },
      {
        path: 'pizza-form/:franchiseId/:branchId/:pizzaId',        
        loadChildren: () => import('./views/pizza-form/pizza-form.module').then(m => m.PizzaFormModule)
      },
      {
        path: 'items/:franchiseId/:branchId/:categoryId/:categoryName',        
        loadChildren: () => import('./views/items/items.module').then(m => m.ItemsModule)
      },
      {
        path: 'item-form/:franchiseId/:branchId/:categoryId/:categoryName/:itemId',        
        loadChildren: () => import('./views/item-form/item-form.module').then(m => m.ItemFormModule)
      },
      
      {
        path: 'branch-form/:franchiseId/:branchId/:activeTab',        
        loadChildren: () => import('./views/branch-form/branch-form.module').then(m => m.BranchFormModule)
      },
      {
        path: 'new-branch-form',        
        loadChildren: () => import('./views/new-branch-form/new-branch-form.module').then(m => m.NewBranchFormModule)
      },
      {
        path: 'new-franchise-form',        
        loadChildren: () => import('./views/new-franchise-form/new-franchise-form.module').then(m => m.NewFranchiseFormModule)
      }
       
    ]
  },
  { path: '**', component: P404Component }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
