import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AppUserService {

  //private order: OrderAppModel;

  constructor(private http: HttpClient) {
  
  }

  public getUserOrders(appUserId): Observable<any> {
   
    return this.http.get(environment.serverUrl +
      'AppUser/GetAppUserLastOrders?appUserId=' + appUserId);
  
    
  }

  public getAppUsers(filter): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'AppUser/GetAppUsersByFilter',
      {
        filter: filter
      });
  }



}
