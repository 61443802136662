import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class DeliveryGuyService {

  //private order: OrderAppModel;

  constructor(private http: HttpClient) {
  
  }

  public getDeliveryGuysReport(filter): Observable<any> {
   
    return this.http.get(environment.serverUrl +
      'IncomingOrders/GetIncomingOrders?date=' + filter.date.toLocaleDateString("pt"));
  
    
  }

  public getAllDeliveryGuys(): Observable<any> {   
    return this.http.get(environment.serverUrl +'DeliveryGuy/GetAll');
  }

  public addDeliveryGuy(deliveryGuy): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'DeliveryGuy/AddDeliveryGuy',
      {
        deliveryGuy: deliveryGuy
      });
  }

  public updateDeliveryGuy(deliveryGuy): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'DeliveryGuy/UpdateDeliveryGuy',
      {
        deliveryGuy: deliveryGuy
      });
  }

  public deleteDeliveryGuy(deliveryGuyId): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'DeliveryGuy/DeleteDeliveryGuy',
      {
        deliveryGuyId: deliveryGuyId
      });
  }

}
