import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
//import { ConfigService } from './common-settings/config.service';
import { environment } from '../../../environments/environment';
//import { AppStorageService } from "../../app.storage.service";
//import {StorageValueEnum} from "../../enums/advanced/storage-value.enum";
import * as moment from "moment";

@Injectable()
export class SignInOutService {

  constructor(private http: HttpClient
             // private configService: ConfigService
              //, private appStorageService: AppStorageService
              ) {

  }

  public signIn(username, password): Observable<any> {
    console.log("signIn " + username, password);
    return this.http.post<any>(environment.serverUrl +
      'Account/Login',
      {
        username: username,
        password: password
      }); 
  }

  public CreateRole(roleName): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Account/CreateRole',
      {
        roleName: roleName 
      }); 
  }

  public GeRoles(): Observable<any> {
     return this.http.get(environment.serverUrl +
      'Account/GeRoles');
  }

  public forgotPassword(email): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Account/ForgotPassword',
      {
        username: email
      });
  }

  public logout(): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      'Account/LogOff',
      {
       // username: email
      });
  }

  public getCurrentUser(): Observable<any> {
    console.log("getCurrentUser");
    return this.http.get(environment.serverUrl +
      'Account/GetCurrentUser');
  }

  public resetPassword() {
    var ResetPasswordBase: any;
    ResetPasswordBase.someValue = 'ResetPassword';
    ResetPasswordBase.someMethod = function () {
      return 'ResetPassword';
    };
    return ResetPasswordBase;
  }


  //public isUserSigned = new BehaviorSubject(false);

 

  public updateUserDetails (user): Observable<any> {
    return this.http.post<any>(environment.serverUrl +
      "Account/UpdateUserDetails",
      {
        user: user
    });
  }

  public signOut() {
   /* this.appStorageService.removeItemInStorage(StorageValueEnum.USER_DETAILS);
    this.appStorageService.removeItemInStorage(StorageValueEnum.LOGIN_TOKEN);
    this.appStorageService.removeItemInStorage(StorageValueEnum.PUSH_TOKEN);
    this.appStorageService.removeItemInStorage(StorageValueEnum.PAYMENT_DATA);
    this.appStorageService.removeItemInStorage(StorageValueEnum.CREDIT_CARD_ENCRYPTED);
    this.appStorageService.removeItemInStorage(StorageValueEnum.CREDIT_CARD_FOR_DIGITS);
    this.appStorageService.removeItemInStorage(StorageValueEnum.CODE_SENT_DATE);
    this.appStorageService.removeItemInStorage(StorageValueEnum.PHONE);
      this.appStorageService.wasScratchDisplayed = false;
      this.appStorageService.isUsedScratchCoupon = false;
      this.appStorageService.useScratchCoupon = true;*/
  }

  public isLoggedIn() {
   // return moment().isBefore(this.getExpiration());
  }

  public isLoggedOut() {
   // return !this.isLoggedIn();
  }

  public getExpiration() {
   /* const expiration = this.appStorageService.getItemFromLocalStorage(StorageValueEnum.EXPIRES_AT);
    if (expiration) {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    } else {
      return new Date();
    }*/
  }

}
