import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { ConfigService } from "./common-settings/config.service";
//import {TranslationsService} from "../../shared/translations/translations.service";

@Injectable()
export class MenuService {

  constructor(private http: HttpClient,
              private configService: ConfigService
             // private translationService: TranslationsService
              ) {}

  public getMenu(): Observable<any> {
    return this.http.get<any>(this.configService.serverUrl + 'Menu/GetMenu?franchiseId=' +
      this.configService.franchiseId);
  }

 /* public getDiscount(userId?): Observable<any> {
    return this.http.get<any>(this.configService.serverUrl + 'MetaData/GetDiscount?franchiseId=' +
      this.configService.franchiseId + '&lang=' + this.translationService.language()+(userId ? '&userId=' + userId : ''));
  }*/

  public getMenuForBranch(branchId, method): Observable<any> {
    return this.http.get<any>(this.configService.serverUrl +
      'Menu/GetMenuForBranch?branchID=' +
      branchId + '&franchiseId=' +
      this.configService.franchiseId + '&method=' + method);
  }

}
