import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs'; 
import { environment } from '../../../environments/environment';

@Injectable()
export class ScratchCouponService {

  constructor(private http: HttpClient) { }

  public getAllItems (franchiseId): Observable<any> {
    return this.http.get(environment.serverUrl + 
      'Cupon/getAllItems?franchiseId=' + franchiseId);
  }

  public getFranchiseScratchCoupon (franchiseId): Observable<any> {
    return this.http.get(environment.serverUrl + 
      'Cupon/GetFranchiseScratchCoupon?franchiseId=' + franchiseId);
  }

  public createOrEdit (coupon,franchiseId): Observable<any>  {
    return this.http.post(environment.serverUrl + 
      'Cupon/CreateOrEditFranchiseScratchCoupon', 
       this.createFranchiseCouponData(coupon, franchiseId) );
  }

  
  public deleteScratchCoupon(couponId): Observable<any> {
    return this.http.post(environment.serverUrl +  "Cupon/DeleteScratchCoupon",
      {
        CouponId: couponId
      }
    );
  }
  
  private createFranchiseCouponData(coupon, franchiseId) {
    let fd = new FormData();

    console.log(coupon);

    if (coupon.Id > 0) fd.append("Id", coupon.Id);
    else fd.append("Id", '0');

    fd.append("franchiseId",franchiseId);
    if (coupon.MinOrderSum) fd.append("MinOrderSum", coupon.MinOrderSum);

    if (coupon.Description) fd.append("Description", coupon.Description);

    if (coupon.Image) fd.append("Image", coupon.Image);
    fd.append("IsActive", coupon.IsActive);

    coupon.Coupons.forEach(function (c, index) {
      if (c.Id > 0) fd.append("Coupons[" + index + "].Id", c.Id);
      else fd.append("Coupons[" + index + "].Id", '0');
      if (c.ValidDayes) fd.append("Coupons[" + index + "].ValidDayes", c.ValidDayes);
      if (c.MinOrderSum) fd.append("Coupons[" + index + "].MinOrderSum", c.MinOrderSum);
      if (c.Percent) fd.append("Coupons[" + index + "].Percent", c.Percent);

      fd.append("Coupons[" + index + "].FranchiseCuponsScratchId", coupon.Id > 0 ? coupon.Id : 0);

      if (c.Image) fd.append("Coupons[" + index + "].Image", c.Image);
      if (c.ItemId) fd.append("Coupons[" + index + "].ItemId", c.ItemId);
    });
    console.log(fd);
    return fd;
  }

}
