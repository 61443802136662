import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from "rxjs";
import { ConfigService } from './common-settings/config.service';
//import { TranslationsService } from "../../shared/translations/translations.service";

@Injectable()
export class MetaDataService {

  constructor(private http: HttpClient,
              private configService: ConfigService
              //private translationsService: TranslationsService
              ) {}

  public getFranchiseGraphics(): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'MetaData/GetFranchiseGraphics?franchiseId=' + this.configService.franchiseId);
  }

  /*public getFranchiseWithBranches(): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'MetaData/GetFranchiseWithBranches?currentVersion=' +
      this.configService.currentVersion + '&franchiseId=' +
      this.configService.franchiseId + '&lang=' + this.translationsService.language());
  }*/

  public getCombos(): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'MetaData/GetCombos?franchiseId=' + this.configService.franchiseId);
  }

  public getCombosForBranch(branchId, method): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'MetaData/GetCombosForBranch?branchID=' + branchId + '&franchiseId=' +
      this.configService.franchiseId + '&method=' + method);
  }

  public getDeliveryCitiesInformation(branchId): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'MetaData/GetDeliveryGroupCities?branchId=' + branchId);
  }

  public isOpen(branchId): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'MetaData/IsOpen?branchId=' + branchId);
  }

  public getCoordinates(city, street, number): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'Locations/GetCoordinates?city=' + city + '&street=' + street + '&number=' + number);
  }

  public getCities(): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'Locations/GetCities');
  }

  public getCityStreets(cityCode): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'Locations/GetCityStreets?cityCode=' + cityCode);
  }

  public getDeliveryGroup(lat, lng): Observable<any> {
    return this.http.get(this.configService.serverUrl +
      'Polygon/GetDeliveryGroup?franchiseId=' + this.configService.franchiseId +
      '&lat=' + lat +
      '&lng=' + lng);
  }

}
