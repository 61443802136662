import { BrowserModule } from '@angular/platform-browser';
//import { NgModule } from '@angular/core';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
//import { AppConfig } from './app.config';
import { AppStorageService } from './app.storage.service';
import { CoreModule } from './core/core.module';
import { TranslationsService } from './core/translations/translations.service';
import { TranslationsModule} from './core/translations/translations.module';

import { StorageValueEnum } from './enums/advanced/storage-value.enum';
//import { SharedModule } from './shared/shared.module';
import { FormsModule } from '@angular/forms';
//import {AlertsComponent} from "./views/notifications/alerts.component";
//import {NotificationsModule} from "./views/notifications/notifications.module";
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

import { NgxElectronModule } from 'ngx-electron';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
//import { IncomingOrdersComponent } from './views/incoming-orders/incoming-orders.component';
//import { IncomingOrdersModule } from './views/incoming-orders/incoming-orders.module';
//import { BranchSettingsModule } from './views/branch-settings/branch-settings.module';
//import { AppUserModule } from './views/app-user/app-user.module';
//export function initializeApp(appConfig: AppConfig) {
 // return () => appConfig.load();
//}




export function setupTranslationsFactory(
  service: TranslationsService): Function {
  return () => service.use();
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    CoreModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    TranslationsModule.forRoot(),
    //IncomingOrdersModule,
    //AppUserModule,
    //BranchSettingsModule,
    NgxElectronModule
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    //IncomingOrdersComponent
    //AlertsComponent
  ],
  providers: [TranslationsService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslationsFactory,
      deps: [ TranslationsService ],
      multi: true
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
  //AppConfig,
   // { provide: APP_INITIALIZER,
    //  useFactory: initializeApp,
     // deps: [AppConfig], multi: true },
    AppStorageService,
    CoreModule
   // NotificationsModule
],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
