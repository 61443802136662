import { NgModule } from '@angular/core'
import { MetaDataService } from './services/meta-data.service';
import { ConfigService } from './services/common-settings/config.service';
import { IncomingOrderService } from './services/incoming-order.service';
import { DeliveryGuyService } from './services/delivery-guy.service';
import { AppUserService } from './services/app-user.service';
import { BranchService } from './services/branch.service';
import { FranchiseService } from './services/franchise.service';
import { BrowserIdentificatorService } from './services/common-settings/browser-identificator.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorsInterceptor } from './interceptors/http-errors.interceptor';
import { ErrorService } from './services/common-settings/error.service';
import { MenuService } from './services/menu.service';
import { VersionImageService } from './services/common-settings/version-image.service';
import { CommonFunctionsService } from './services/common-settings/common-functions.service';
import { SignInOutService } from './services/sign-in-out.service';
import { ScratchCouponService } from './services/scratch-coupon.service';
import {PreviousRouteService} from "./services/common-settings/previous-route.service";
import { TranslationsModule } from './translations/translations.module';
import { TranslationsService } from './translations/translations.service';
import { TranslationsPipe } from './translations/translations.pipe';
import {DiscountTypePipe} from "./pipes/discount-type.pipe";
import {RoundPricePipe} from "./pipes/round-price.pipe";
import {HideValuePipe} from "./pipes/hide-value.pipe";
import {DataFilterPipe} from "./pipes/data-filter.pipe";
import {SearchFilterPipe} from "./pipes/search-filter.pipe";

import { CommonModule } from '@angular/common';
@NgModule({
    declarations: [
      DiscountTypePipe,
      RoundPricePipe,
      HideValuePipe,
      DataFilterPipe,
      SearchFilterPipe
    ],
    imports: [
      TranslationsModule,
      CommonModule
    ],
    exports: [
      TranslationsModule,
      DiscountTypePipe,
      RoundPricePipe,    
      HideValuePipe,
      DataFilterPipe,
      SearchFilterPipe
    ],
    providers: [
        MetaDataService,
        IncomingOrderService,
        DeliveryGuyService,
        AppUserService,
        BranchService,
        ConfigService,
        ErrorService,
        MenuService,
        FranchiseService,
        SignInOutService,
        BrowserIdentificatorService,
        PreviousRouteService,
        [
          { provide: HTTP_INTERCEPTORS, useClass: HttpErrorsInterceptor, multi: true }
        ],
        CommonFunctionsService,
        TranslationsService,
        VersionImageService,
      ScratchCouponService
    ],
    entryComponents: [],

})
export class CoreModule { }
