import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {CommonFunctionsService} from "./core/services/common-settings/common-functions.service";
//import { CategoryAppAdvancedModel } from './models/advanced/menu/category-app-advanced.model';
//import { PizzaAppAdvancedModel } from './models/advanced/pizza/pizza-app-advanced.model';
//import { ToppingAppModel } from './models/menu/topping-app.model';
import {UserAppModel} from "./models/user/user-app.model";
import {BranchAppModel} from "./models/franchise-branch/branch-app.model";
import {FranchiseAppModel} from "./models/franchise-branch/franchise-app.model";
import {OrderAppModel} from './models/order/order-app.model';
import { INavData } from '@coreui/angular';
//import ComboAppModel from "./models/combo/combo.model";
//import ComboAppAdvancedModel from "./models/advanced/combo/combo-app-advanced.model";
import {BranchTabEnum} from "./enums/branch-tab.enum";
//import {DeliveryGroupAppModel} from "./models/order/delivery-group-app.model";


/*
* function body that test if storage is available
* returns true if localStorage is available and false if it not
*/
function lsTest(){
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
}

/*/!*
* execute Test and run our custom script
*!/
if(lsTest()) {
  // window.sessionStorage.setItem(name, 1); // session and storage methods are very similar
  window.localStorage.setItem(name, 1);
  console.log('localStorage where used'); // log
} else {
  document.cookie="name=1; expires=Mon, 28 Mar 2016 12:00:00 UTC";
  console.log('Cookie where used'); // log
}*/

function cookieStorage() {
  const cookieValue = document.cookie;
  if (cookieValue) {

  }
}

@Injectable()
export class AppStorageService {
  
  
  public orders: OrderAppModel[];
  
  //public categories: CategoryAppAdvancedModel[];
  //public pizzas: PizzaAppAdvancedModel[];
  //public pizzaToppings: ToppingAppModel[];
  //public combos: ComboAppAdvancedModel[];
  // To save current branch;
  public user: UserAppModel;
  public userId: number;
  public userName: string;
  public branch: BranchAppModel;
  public branches: BranchAppModel[];
  public branchAndGroup: any;
  public franchise: any;//FranchiseAppModel;
  public displaySideBar:boolean = false;
  public paymentResult: any;
  private deviceInfo: any;
  private isMobile : boolean;
  private isTablet : boolean;
  private isDesktop: boolean;
  //public deliveryGroup: DeliveryGroupAppModel;

  // Checks need of loading scratch coupon to user to choose option
  public useScratchCoupon: boolean = true;
  // checks if user will use scratch coupon in the order
  public isUsedScratchCoupon: boolean = false;
  public wasScratchDisplayed: boolean = false;
  public paymentOptions: any;
 // public branches: BranchAppModel[];

  public isMenuWasLoaded: boolean = false;
  public backResultMenu: any;
  public backResultCombo: any;

  public isFranchiseBranchesWasLoaded: boolean = false;
  public backResultFranchiseBranches: any;

  public branchTabEnum: BranchTabEnum;

  public startingPage: string;

  constructor(private http: HttpClient,
              private commonFunctionService: CommonFunctionsService)
              {}

 
 // public getOrders(): OrderAppModel[]{
   // return this.orders;
 // }              
 
  public setDeviceInfo(deviceInfo:any, isMobile:boolean, isTablet:boolean, isDesktop:boolean) {
    this.deviceInfo = deviceInfo;
    this.isMobile = isMobile;
    this.isTablet = isTablet;
    this.isDesktop = isDesktop;
  }


  public getDeviceInfo():any {
    return this.commonFunctionService.deepCopy(this.deviceInfo);
  }
  public getIsMobile():boolean {
    return this.commonFunctionService.deepCopy(this.isMobile);
  }
  public getIsTablet():boolean {
    return this.commonFunctionService.deepCopy(this.isTablet);
  }
  public getIsDesktop():boolean {
    return this.commonFunctionService.deepCopy(this.isDesktop);
  }

  public setItemInLocalStorage(key, value) {
    if (lsTest()) {
      localStorage.setItem(key, JSON.stringify(value));
    }
  }

  public getItemFromLocalStorage(key) {
    if (lsTest()) {
      const value = localStorage.getItem(key);
      if (value == undefined) {
        return value;
      }
      else {
        try {
          return JSON.parse(value);
        } catch (e) {
          return value;
        }
      }
    }
  }

  public removeItemInStorage(key) {
    if (lsTest()) {
      localStorage.removeItem(key);
    }
  }

}
